export const WF_MORE_WORKFLOW_LOADED = "WF_MORE_WORKFLOW_LOADED"
export const WF_UPDATE_STATE = "WF_UPDATE_STATE"
export const WF_CREATED = "WF_CREATED"
export const WF_DELETED = "WF_DELETED"
export const WF_UPDATED = "WF_UPDATED"
export const WF_DIAGRAM_RECEIVED = "WF_DIAGRAM_RECEIVED"
export const WF_UPDATE_DIAGRAM_STATE = "WF_UPDATE_DIAGRAM_STATE"
export const WF_UPDTAE_CURRENT_ELEMENTS = "WF_UPDTAE_CURRENT_ELEMENTS"
export const WF_DELETE_CURRENT_ELEMENTS = "WF_DELETE_CURRENT_ELEMENTS"
export const WF_ENV_VARIABLE_CREATED = "WF_ENV_VARIABLE_CREATED"
export const WF_ENV_VARIABLE_UPDATED = "WF_ENV_VARIABLE_UPDATED"
export const WF_ENV_VARIABLE_DELETED = "WF_ENV_VARIABLE_DELETED"
export const WF_DIY_ALLOWED_UPDATED = "WF_DIY_ALLOWED_UPDATED"
export const WF_LOAD_DEPLOYMENT_LOGS = "WF_LOAD_DEPLOYMENT_LOGS"
export const WF_LOAD_BUILD_VERSION = "WF_LOAD_BUILD_VERSION"
